import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

class DialogData {
  message: string;
  accept: (inputValue?: string) => void;
  cancel: () => void;
  icon: string;
  title: string;
  input?: boolean;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule, MatButtonModule, FormsModule,MatFormFieldModule,MatError]
})
export class AlertComponent implements OnInit {

  public title = 'Info';
  public icon = 'priority_high';
  public inputValue = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly dialogRef: MatDialogRef<AlertComponent>
  ) { }

  ngOnInit() {
    if (this.data.title != undefined) {
      this.title = this.data.title;
    }
    if (this.data.icon != undefined) {
      this.icon = this.data.icon;
    }
    this.setMessage();
  }

  private setMessage() {
    const messageElement = document.getElementById('div-mensaje');
    if (messageElement) {
      messageElement.innerHTML = this.data.message;
    }
  }

  public cancel() {
    this.data.cancel();
    this.dialogRef.close();
  }

  public accept() {
    this.data.accept(this.data.input ? this.inputValue : undefined);
    this.dialogRef.close();
  }

  public close() {
    this.dialogRef.close();
  }

}
