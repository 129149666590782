<div class="container-dialog-alert">
  <div class="dialog-t" mat-dialog-title>
    <mat-icon>
      {{icon}}
    </mat-icon>
    <br>
    <strong>{{title}}</strong>
  </div>
  <div>
    <div id="div-mensaje"></div>
    <div *ngIf="data.input">
      <input class="input_value" matInput [(ngModel)]="inputValue">
    </div>
    <div class="buttons">
      <div *ngIf="data.cancel !== undefined">
        <button (click)="cancel();" mat-button>Cancelar</button>
      </div>
      <div *ngIf="data.accept !== undefined">
        <button (click)="accept()" color="primary" mat-raised-button>Aceptar</button>
      </div>
      <div *ngIf="data.accept === undefined">
        <button (click)="close()" color="primary" mat-raised-button>Aceptar</button>
      </div>
    </div>
  </div>
</div>
